.notFoundContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

.childContainer {
  width: 40%;
  height: min-content;
  text-align: center;
  display: inline-block;
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 20px;
}
.childContainer p {
  margin: 10px 0;
}

.childContainer a {
  text-decoration: none;
  font-weight: bold;
  font-size: larger;
}
